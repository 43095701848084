._tch{
  max-width: 90%;
  margin: 10% auto;
}

.tch{
  width: 100%;
  /* margin:auto; */
}

@media only screen and (max-width: 500px) {
  ._tch{
    margin-top: 25%;
  }
} 