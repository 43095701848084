.ctdt_7llp{
  max-width: 1200px;
  margin: 96px auto 10px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

._7llp{
  margin-top: 20px;
  width: 1000px;
  border-radius: 20px;
  box-shadow: rgb(87, 90, 85) 0px 4px 8px -2px, rgb(87, 90, 85) 0px 0px 0px 1px;
}

._7llp_sub{
  padding: 5px 40px;
  margin-bottom: 40px   ;
}

._7llp_title{
  text-align: center;
  font-size: 2.5rem;
  cursor: pointer;
  margin: 25px 0 0 0;
}

._7llp_texts{
  font-size: 1.5rem;
  margin: 0;
  padding: 10px 20px;
}

.op{
  color: red;
  font-size: 1rem;
}

._7llp_list{
  margin: 0;
  font-size: 1.5rem;
  list-style: decimal;
  padding: 0 25px;  
  margin-bottom: 40px;
}

.key{
  margin: 0 20px;
}

._7llp_sub input[type = text], input[type=radio]  {
  margin-left: 20px;
  font-size: 1.5rem;
  border: none;
  border-bottom: 1px solid;
}

._7llp_sub input:focus {
  outline: none;
}

.text_monhoc{
  padding: 0 60px;
}

.btn_7llp {
  margin: 1.5rem auto;
  --color: 180,255, 100;
  border-radius: .5em;
  transition: .3s;
  color: rgb(0, 123, 191);
  fill: rgb(0, 123, 191);
  font-family: monospace;
  font-weight: bolder;
  font-size: x-large;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
  border: 2px solid rgb(0, 123, 191);
  box-shadow: 0 0 10px rgb(103, 148, 142);
  outline: none;
  display: flex;
  align-items: center;
  padding: .5em 1em;
}

.btn_7llp:hover {
  box-shadow: 0 0 0 5px rgb(103, 148, 142);
}

.btn_7llp span {
  transform: scale(.8);
  transition: .3s;
}

.btn_7llp:hover span {
  transform: scale(1);
}

.btn_7llp svg {
  font-size: 0;
  transform: scale(0.5) translateX(0%) rotate(-180deg);
  transition: .3s;
}

.btn_7llp:hover svg {
  font-size: 20px;
  transform: scale(1) translateX(20%) rotate(0deg);
}

.btn_7llp:active {
  transition: 0s;
  box-shadow: 0 0 0 5px rgb(0, 123, 191);
}

label{
  font-size: 1.3rem;
  /* text-align: center; */
}

input[type= radio]{
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.save {
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  margin-top: 15px;
  font-family: inherit;
  font-size: 20px;
  background: #212121;
  color: white;
  fill: rgb(155, 153, 153);
  padding: .7em 1em;
  padding-left: 0.9em;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 15px;
  font-weight: 1000;
}

.save span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

.save svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

.save:hover {
  background: #000;
}

.save:hover .svg-wrapper {
  transform: scale(1.25);
  transition: 0.5s linear;
}

.save:hover svg {
  transform: translateX(1.2em) scale(1.1);
  fill: #fff;
}

.save:hover span {
  opacity: 0;
  transition: 0.5s linear;
}

.save:active {
  transform: scale(0.95);
}


@media only screen and (max-width: 500px) {
  .ctdt_7llp{
    width: 100%;
  }

  ._7llp{
    width: 100%;
  }

  ._7llp_sub{
    padding: 0 5px;
    font-size: 1.2rem;
  }

  ._7llp_title{
    font-size: 1.5rem;
  }

  ._7llp_texts{
    font-size: 1.4rem;
  }

  ._7llp_list{
    font-size: 1.2rem;
    margin-bottom: 20px;
  }

  .op{
    font-size: 1rem;
  }

  input[type= radio]{
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }

  ._value{
    font-size: 1.2rem;
  }
}