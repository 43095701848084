/* Navbar.css */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
  background-color: transparent;
  color: #158dbd;
  z-index: 10000;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.mb-nav {
  display: none;
}

.navbars {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
  color: #fff;
  z-index: 10000;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #158dbd;
}

.navbaradmin {
  position: fixed;
  top: 0;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
  background-color: transparent;
  color: #158dbd;
  z-index: 10000;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.navbar-logo {
  cursor: default;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
}


.navbar-logo .span {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 30px;
}

.tennv{
  display: block;
}


.nav-links {
  color: #158dbd;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.nav-links li {
  margin-right: 25px;
}

.nav-links a {
  cursor: pointer;
  padding: 12px 16px;
  margin: 0;
  /* color: #f1f1f1; */
}

.nav-links a:hover {
  background-color: #35aedf;
}

.nav-links a, .nav-links p {
  /* text-align: center; */
  font-weight: 800;
  font-size: 25px;
  text-decoration: none;
  color: #18d4c5;
}

.alink{
  font-size: 15px !important;
}

/* Dropdown */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #158dbd; /* Màu nền dropdown */
  box-shadow: 0 2px 8px rgba(99, 99, 99, 0.2);
  min-width: 160px;
  z-index: 1;
  border-radius: 5px; /* Thêm góc bo tròn cho dropdown */
  opacity: 0; /* Ẩn dropdown ban đầu */
  transition: opacity 0.3s ease-in-out; /* Hiệu ứng chuyển đổi */
}

.dropdown-content a {
  color: #f1f1f1;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  color: #000405;
  background-color: #35aedf; /* Màu nền khi hover */
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown p {
  cursor: pointer;
  padding: 12px 16px;
  margin: 0;
  /* color: #f1f1f1; */
}

.dropdown:hover p {
  background-color: #35aedf; /* Màu nền khi hover */
}

.dropdown:hover .dropdown-content {
  display: block;
  opacity: 1; /* Hiển thị dropdown */
}

.search-bar {
  display: flex;
  min-width: 50%;
}

.search-bar-back {
  font-size: 25px;
}

.search-bar input {
  font-size: 25px;
  width: 100%;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.button {
  outline: none;
  width: 56px;
  height: 56px;
  margin: 0 3rem 0 1rem;
  position: relative;
  border: none;
  background-color: white;
  color: #212121;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  gap: 10px;
  border-radius: 10px;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  overflow: hidden;
}

.button:before,
.button:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.button:before {
  border: 4px solid #f0eeef;
  transition: opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1) 80ms,
    transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 80ms;
}

.button:after {
  border: 4px solid #96daf0;
  transform: scale(1.3);
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0;
}

.button:hover:before,
.button:focus:before {
  opacity: 0;
  transform: scale(0.7);
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.button:hover:after,
.button:focus:after {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1) 80ms,
    transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 80ms;
}

.button-box {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.button-elem {
  display: block;
  width: 20px;
  height: 20px;
  margin: 17px 18px 0 18px;
  transform: rotate(180deg);
  fill: #f0eeef;
}

.button:hover .button-box,
.button:focus .button-box {
  transition: 0.4s;
  transform: translateX(-56px);
}

.button span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.button::before {
  content: "";
  position: absolute;
  background-color: palevioletred;
  width: 100%;
  height: 100%;
  left: 0%;
  bottom: 0%;
  transform: translate(-100%, 100%);
  border-radius: inherit;
}

.button svg {
  fill: rgb(0, 123, 119);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.button:hover::before {
  animation: shakeBack 0.6s forwards;
}

.button:hover svg {
  fill: white;
  /* scale: 1.3; */
}

.button:active {
  box-shadow: none;
}

@keyframes shakeBack {
  0% {
    transform: translate(-100%, 100%);
  }

  50% {
    transform: translate(20%, -20%);
  }

  100% {
    transform: translate(0%, 0%);
  }
}

/* Banner */

.banner {
  position: relative;
  height: 520px;
}

.img {
  /* margin-top: 96px; */
  width: 100%;
  height: 100%;
  object-fit: none;
}

.text-banner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 4rem;
  cursor: default;
}

.Btn {
  --black: #000000;
  --ch-black: #141414;
  --eer-black: #1b1b1b;
  --night-rider: #2e2e2e;
  --white: #ffffff;
  --af-white: #f3f3f3;
  --ch-white: #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 56px;
  height: 56px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: 0.3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: var(--af-white);
}

/* plus sign */
.sign {
  width: 100%;
  transition-duration: 0.3s;
  display: flex;
  /* align-items: center;  */
  justify-content: center;
}

.sign svg {
  width: 17px;
}

.sign svg path {
  fill: var(--night-rider);
}
/* text */
.textbtn {
  position: absolute;
  right: 0%;
  width: 0%;
  opacity: 0;
  color: var(--night-rider);
  font-size: 1.2em;
  font-weight: 600;
  transition-duration: 0.3s;
}
/* hover effect on button width */
.Btn:hover {
  width: 130px;
  border-radius: 5px;
  transition-duration: 0.3s;
}

.Btn:hover .sign {
  width: 30%;
  transition-duration: 0.3s;
}
/* hover effect button's text */
.Btn:hover .textbtn {
  opacity: 1;
  width: 70%;
  transition-duration: 0.3s;
  padding-right: 10px;
}
/* button click effect*/
.Btn:active {
  transform: translate(2px, 2px);
}

/* CSS cho điện thoại di động */
@media only screen and (max-width: 500px) {
  /* Điều chỉnh các kiểu cho nav */
  .banner {
    width: 100%;
    height: auto;
  }

  .mb-navbars {
    background-color: #136e92;
    padding: 0;
    height: 10%;
  }

  .navbar-logo,
  .nav-links {
    display: none;
  }
  .mb-nav {
    margin: auto;
    position: fixed;
    right: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    z-index: 1999999;
    overflow: hidden;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    /* box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px; */
  }

  .mb-nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 20%;
    padding: 2px 0;
  }

  .text-banner {
    display: none;
  }

  .mb_more {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
  }

  .mb_more {
    z-index: 9999;
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: #155f82;
    right: 0;
    top: 0%;
    padding: 100px 0;
    transition: all 0.2s ease;
  }

  .mb_more a {
    background-color: #5099b9;
    border-radius: 10px;
    text-transform: uppercase;
    margin: 5px 10px;
    border: 1px #d1d1d1 solid;
    text-decoration: none;
    padding-left: 20px;
    color: white;
    line-height: 40px;
    font-size: 30px;
  }

  .close-more {
    margin: 15px auto;
    display: flex;
    height: 3em;
    width: 100px;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee4b;
    border-radius: 3px;
    letter-spacing: 1px;
    transition: all 0.2s linear;
    cursor: pointer;
    border: none;
    background: #fff;
  }

  .close-more > svg {
    margin-right: 5px;
    margin-left: 5px;
    font-size: 20px;
    transition: all 0.4s ease-in;
  }

  .close-more:hover > svg {
    font-size: 1.2em;
    transform: translateX(-5px);
  }

  .close-more:hover {
    box-shadow: 9px 9px 33px #d1d1d1, -9px -9px 33px #ffffff;
    transform: translateY(-2px);
  }
}
