.container{
  width: 1200px;
  margin: 15px auto;
}

.container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 100%;
  margin: 5% auto;
}

.subjects-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.subjects-table th,
.subjects-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.subjects-table th {
  background-color: #f2f2f2;
  color: black;
  position: sticky;
  top: 0;
  z-index: 1;
}

.subjects-table td a {
  color: #007bff;
  text-decoration: none;
}

.subjects-table td a:hover {
  text-decoration: underline;
}


.content{
  display: flex;
}

.ctdt{
  width: 1000px;
}

.img-ctdt{
  max-width: 500px;
  height: auto; 
  margin-right: 30px;
}

.text-ctdt{
  cursor: pointer;
  margin: 0;
  font-size: 3rem;
  color: rgb(0, 123, 191);
  text-transform: uppercase;
  border-top: 5px solid rgb(240, 240, 240);
  border-bottom: 5px solid rgb(240, 240, 240);
  margin-bottom: 2.5rem;
}

.text-ctdt-sub{
  color: black;
  font-size: 2rem;
}

.text-ctdt-sub:hover{
  color: rgb(119, 126, 126);
  transition: all 0.9s cubic-bezier(0.23, 1, 0.320, 1);
}

@media only screen and (max-width: 500px) {
  .container{
    width: 100%;
  }

  .content{
    width: 100% ;
    display: block;
  }

  .ctdt{
    width: 100%;
  }

  .img-ctdt{
    display: none;
  }

  .text-ctdt{
    font-size: 1.5rem;
    margin: 0 0 15px 0;
    padding-left: 10px;
  }

  .text-ctdt-sub{
    margin: 0;
    font-size: 1rem;
    padding-left: 10px;
  }
}

._sk{
  align-items: center;
  max-width: 80%;
  margin: 10% auto;
}

.sk{
  width: 100%;
  margin:auto;
}

._sk{
    margin-top: 25%;
  }