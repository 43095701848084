.home{
  max-width: 1200px;
  margin:20px auto;
}

.section{
  margin: 25px 0;
}

.mb-video{
  display: none;
}

.content{
  margin: 30px 0;
}

.title {
  font-size: 40px;
  font-weight: 700;
  text-align: justify;
}

.text{
  color: #000;
  font-size: 20px;
  font-weight: 500;
}

.en{
  margin: 5px 0;
  color:rgba(3, 3, 82, 0.897) ;
}

.center{
  text-align: center;
  margin-bottom: 15px;
}

._title_sub{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


._section{
  display: flex;
}

.title-pro{
  width: 33.33%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

._img{
  width: 250px;
}


/* CSS cho điện thoại di động */
@media only screen and (max-width: 500px) {
  /* Điều chỉnh các kiểu cho nav */

  .video{display: none;}

  .mb-en{
    display: block;
    margin: 0;
  }

  .mb-video{
    display: block;
    width: 100%;
    height: 100%;
  }
  
  .mb-text{
    margin-top: 66px;
  }

  .title{
    font-size: 1.5rem;
  }

  .home{
    text-align: center;
  }

  ._img{
    width: 50%;
    /* height: 50%; */
  }
  
  .text{
    font-size: 1rem;
  }

  .mb-text_en{
    display: block;
    margin-top: 46px;
  }

  .mb-text_ens{
    display: block;
    margin-top: 16px;
  }
}