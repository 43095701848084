/* UnderDevelopment.css */

.under-development-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.under-development-content {
  text-align: center;
  max-width: 500px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.h1_dev {
  font-size: 72px;
  color: #ff6f61;
}

.h2_dev {
  font-size: 28px;
  color: #333;
  margin-top: 10px;
}

.p_dev {
  font-size: 18px;
  color: #666;
  margin-top: 20px;
}

.actions {
  margin-top: 30px;
}

.btn-primary {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  margin-right: 10px;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  display: inline-block;
  padding: 10px 20px;
  background-color: #6c757d;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.btn-secondary:hover {
  background-color: #495057;
}
