.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* App.css */

#wrapper {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

/* Sidebar styles */
#sidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: #343a40;
  z-index: 1000;
  overflow-y: auto;
}

.sidebar-heading {
  padding: 1rem;
  font-size: 1.2rem;
  text-align: center;
  background-color: #212529;
}

/* Container for main content */
#page-content-wrapper {
  margin-left: 250px; /* Same width as sidebar */
  width: calc(100% - 250px);
  padding-top: 56px; /* Adjust to match header height */
  overflow-y: auto;
}
.bg-dark {
  background-color: #343a40 !important;
}

.text-white {
  color: #ffffff !important;
}

._form{
  background-color: #ffffff;
  margin: auto;
  display: flex;
  padding: 0 50px 10px;
  width: 80%;
  height: 100%;
  text-align: center;
  flex-direction: column;
  align-items: stretch;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

._label{
  text-align: left !important;
  font-weight: 600;
}
._row{
  border-radius: 10px;
  box-shadow: rgba(253, 0, 0, 0.05) 0px 0px 0px 1px;
}

._btn{
  width: 50%;
  text-align: center !important;
}

.dropdown-menu{
  width: 100%;
}

.check-box > input[type="checkbox"]{
  height: 25px;
  width: 25px;
  margin-right: 10px;
}