.about{
  max-width: 1200px;
  margin: 96px auto 10px auto;
  display: flex;
  justify-content: center;
}

.user{
  width: 380px;
  padding: 6px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 10px;
  text-align: center;
}

.img-user{
  width: 307px;
  height: 307px;
}

.name-user{
  padding: 0 5px;
  text-transform: uppercase;
  font-size: 25px;
  cursor: pointer;
}

.title-user{
  font-size: 20px;
  cursor: pointer;
}

@media only screen and (max-width: 500px){
  .about{
    max-width: 600px;
    margin: 96px 0;
    display: block;
  }

  .user{
    margin: 15px 17px;
  }

  .img-user{
    width: 250px;
    height: 250px;
  }

  .name-user{
    font-size: 20px;
  }

  .title-user{
    font-size: 17px;
  }
}