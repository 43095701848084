.questions-form {
  display: block;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.questions-form h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.question-item {
  font-size: 20px;
  text-align: left;
  margin-bottom: 20px;
}

.question-text {
  font-weight: bold;
  font-size: 20px;
  /* color: #555; */
  margin-bottom: 10px;
}

.answers {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.answer-option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.input-maNV {
  margin-bottom: 20px;
}

.input-maNV label {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #333;
}

.input-field {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.form-check-input{
  appearance: auto;
}

.submit-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

.submission-message {
  margin-top: 20px;
  text-align: center;
  color: #28a745;
  font-weight: bold;
}
.questions-form {
  position: relative; /* Ensure relative positioning for overlay */
}

.notification-overlay {
  position: fixed; /* Fixed position to overlay on top */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background-color: rgb(245 243 243 / 82%);
  display: flex; /* Center content */
  justify-content: center;
  align-items: center;
  z-index: 100; /* Ensure it's above other content */
}

.notification-box {
  background-color: #24c52b; /* Green background */
  color: white; /* White text */
  padding: 20px; /* Some padding */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add some shadow */
  display: flex; /* Align items */
  justify-content: space-between; /* Spread items */
  align-items: center; /* Center items vertically */
  font-size: x-large;
}

.notification-box .close-button {
  margin-left: 10px;
  padding: 5px;
  background: none; /* No background */
  border:none; /* No border */
  color: white; /* White text */
  font-size: 30px; /* Larger font */
  cursor: pointer; /* Pointer cursor */
}
